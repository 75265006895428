import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { Container } from '@material-ui/core'
import SEO from 'components/Seo'
import Button from 'components/widgets/Button'
import ModalVideo from 'components/Video/ModalVideo'
import { getListUsersName } from 'utils'
import { pageView } from 'utils/tracker'
import { isBrowserIE } from 'utils/browser'
import { TRACKING, TOUR_WELCOME_VIDEO_URL } from 'config/constants/common'
import './index.scss'

export default function TourWelcome(props) {
  const { t } = useTranslation(['welcome', 'home', 'tour'])
  const [isOpenVideo, setOpenVideo] = useState(false)
  const { data } = props
  const { postgres } = data || {}
  const { ecmsEventTourByTourCode: tourDetail } = postgres || {}
  const { ecmsEventTourVipsByTourId, ecmsEventTourPresentersByTourId } = tourDetail || {}
  const { nodes: vips = [] } = ecmsEventTourVipsByTourId || {}
  const { nodes: presenters = [] } = ecmsEventTourPresentersByTourId || {}
  const vipsName = getListUsersName(vips, 'ecmsUserByUserId')
  const presentersName = getListUsersName(presenters, 'ecmsUserByUserId')
  const trackingEventSource = TRACKING.SOURCE.TOUR.WELCOME

  useEffect(() => {
    pageView(trackingEventSource)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="TourWelcome-screen Layout-children">
      <SEO title={`${t('lbl_welcome_en')}`} />
      <div className={`TourWelcome-container ${isBrowserIE() && 'ie'}`}>
        <Container className="TourWelcome-mainContainer">
          <div className="TourWelcome-intro">
            <h4 className="TourWelcome-title">{t('tour:lbl_vip_presentation_en')}</h4>
            <p className="TourWelcome-subTitle">{t('home:lbl_slogan_en')}</p>
            <div className="TourWelcome-content">
              <div className="TourWelcome-authors">
                <p className="TourWelcome-sectionLabel">{t('home:lbl_title_welcome_year')}</p>
                <p className="TourWelcome-label">{t('common:lbl_vip')}: </p>
                <p className="TourWelcome-value">{vipsName}</p>
                <p className="TourWelcome-label">{t('common:lbl_attendant')}: </p>
                <p className="TourWelcome-value">{presentersName}</p>
                <Button
                  className="TourWelcome-btn"
                  label={t('tour:lbl_welcome_btn_video_en')}
                  onClick={() => setOpenVideo(true)}
                />
              </div>
              <div className="TourWelcome-desc">
                <p dangerouslySetInnerHTML={{ __html: t('tour:lbl_welcome_desc') }} />
              </div>
            </div>
            <Button
              className="TourWelcome-btn sp-view"
              label={t('tour:lbl_welcome_btn_video_en')}
              onClick={() => setOpenVideo(true)}
            />
          </div>
        </Container>
      </div>
      <ModalVideo
        isOpen={isOpenVideo}
        video={{ url: TOUR_WELCOME_VIDEO_URL, fileCategory: 'mp4' }}
        hiddenPip
        onClose={() => setOpenVideo(false)}
      />
    </div>
  )
}

export const query = graphql`
  query($tourCode: String!) {
    postgres {
      ecmsEventTourByTourCode(tourCode: $tourCode) {
        tourId
        tourName
        tourCode
        ecmsEventTourVipsByTourId {
          nodes {
            ecmsUserByUserId {
              displayName
            }
          }
        }
        ecmsEventTourPresentersByTourId {
          nodes {
            ecmsUserByUserId {
              displayName
            }
          }
        }
      }
    }
  }
`
